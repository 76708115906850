import { getRepoTooltip, getStatusColor, getStatusText } from "~/lib/dependencyHelpers";
import { type RepoDependenciesEvaluation } from "@openqlabs/drm-db";
import { decodeStatusAsObject } from "@openqlabs/utils";
import { useContact } from "~/providers/ContactProvider";
export default function RepoDependenciesFoundPresentation({
  dependenciesSearched,
  repoDepsEvaluation,
  className,
  trackerName
}: Readonly<{
  dependenciesSearched: string[];
  repoDepsEvaluation: RepoDependenciesEvaluation;
  className?: string;
  trackerName: string;
}>) {
  const {
    contact
  } = useContact();
  const manualStatus = contact.manualStatus;
  if (manualStatus) {
    const depStatusObj = decodeStatusAsObject(contact.dependencyStatusNumber, manualStatus);
    return <div className="group flex items-center space-x-2 pt-2 pb-12">
        <div className={`h-3 w-3 rounded-full ${depStatusObj.color}`} />
        <div>{dependenciesSearched[0]}</div>
        <div className={`whitespace-nowrap rounded-md px-4 ${depStatusObj.color}`}>
          {depStatusObj.status}
        </div>
        <div className="relative z-50 opacity-0 transition-opacity group-hover:opacity-100">
          <div className="absolute -ml-40 -mt-6 whitespace-nowrap rounded border border-gray-100 bg-white px-2 py-1 shadow-lg">
            {depStatusObj.tooltip}
          </div>
        </div>
      </div>;
  }
  const repoDepOverview = repoDepsEvaluation?.result;
  // only keeping the below code because of the multiple dep logic for now
  return <div className={`flex flex-wrap gap-8 ${className ?? ""}`} data-sentry-component="RepoDependenciesFoundPresentation" data-sentry-source-file="RepoDependenciesFoundPresentation.tsx">
      {dependenciesSearched.length === 0 && <div className="m-auto flex whitespace-nowrap rounded-md bg-offwhite px-4 text-sm">
          no status
        </div>}
      {!repoDepOverview?.earliestFound ? null : <div className="group flex items-center space-x-2 pt-2 pb-12">
          <div className={`h-3 w-3 rounded-full ${getStatusColor(repoDepOverview.repoStatus ?? "")}`} />
          <div>{trackerName}</div>
          <div className={`whitespace-nowrap rounded-md px-4 ${getStatusColor(repoDepOverview.repoStatus ?? "")}`}>
            {getStatusText(repoDepOverview.repoStatus ?? "cold")}
          </div>
          <div className="relative z-50 opacity-0 transition-opacity group-hover:opacity-100">
            <div className="absolute -ml-40 -mt-6 whitespace-nowrap rounded border border-gray-100 bg-white px-2 py-1 shadow-lg">
              {getRepoTooltip(repoDepOverview.repoStatus ?? "", repoDepOverview.earliestFound, repoDepOverview.dateRemoved)}
            </div>
          </div>
        </div>}
    </div>;
}