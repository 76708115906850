import { useEffect, useState } from "react";
import { getActiveColor, getActiveWidth } from "~/lib/utils";
export default function CardScore({
  label,
  score
}: Readonly<{
  label: string;
  score: number | null;
}>) {
  const activeClass = getActiveColor(label.toLowerCase());
  const [animationKey, setAnimationKey] = useState(0);
  const getScore = (score: number | null) => {
    if (!score) return 0;
    if (score <= 0) return 0;
    if (score >= 10) return 10;
    if (!score) return 0;
    return Math.round(score);
  };
  useEffect(() => {
    if (animationKey === 5) return;
    setTimeout(() => {
      setAnimationKey(animationKey + 1);
    }, 200);
  }, [animationKey]);
  const parsedScore = getScore(score);
  return <div className="flex items-center self-start " data-sentry-component="CardScore" data-sentry-source-file="Score.tsx">
      <span className="mr-2 w-24 text-muted">{label}</span>
      <div className={`w-30 ${getActiveWidth(10)}`}>
        <div className={`h-4 ${activeClass} ${getActiveWidth(parsedScore)} rounded-lg`}></div>
      </div>
    </div>;
}