import { ChevronDownIcon } from "@primer/octicons-react";
import { useState } from "react";
import LoadingSpinner from "~/loaders/LoadingSpinner";
import Button from "~/components/base/Button";
import { useLogger } from "~/providers/LoggerProvider";
import { useTeamAccount } from "~/providers/TeamAccountProvider";
import { useTeamAccountUser } from "~/providers/TeamAccountUserProvider";
import { api } from "~/utils/api";
import AddUserRepoButton from "./AddUserRepoButton";
import { trpc } from "~/store/trpc";
import { useList } from "~/providers/ListProvider";
export default function AddUserRepoToList({
  repoName
}: Readonly<{
  repoName: string;
}>) {
  const {
    activeTeamAccount
  } = useTeamAccount();
  const logger = useLogger();
  const [teamAccountUser] = useTeamAccountUser();
  const [upsertingContact, setUpsertingContact] = useState<boolean>(false);
  const [repoContactAdded, setRepoContactAdded] = useState<boolean>(false);
  const {
    refetch,
    data: contactExists
  } = api.githubContact.getContactByDisplayNames.useQuery(repoName, {
    refetchInterval: data => {
      if (repoContactAdded && data) {
        return 1000 * 60;
      }
      return false;
    }
  });
  const list = useList();
  const [showDropdown, setShowDropdown] = useState(false);
  const handleFirstClick = async () => {
    setShowDropdown(true);
    try {
      setUpsertingContact(true);
      await trpc.contactBatchCreation.create.mutate({
        type: "repo",
        listId: list.id ?? "",
        teamAccountId: activeTeamAccount?.id ?? "",
        userId: teamAccountUser?.id ?? "",
        githubUrls: [{
          url: repoName.toLowerCase(),
          customFields: []
        }]
      });
      setRepoContactAdded(true);
      setTimeout(async () => {
        await refetch();
      }, 3000);
    } catch (err) {
      logger.error(err, "AddUserRepoToList.tsx");
    }
    setUpsertingContact(false);
  };
  return <>
      {contactExists ? <AddUserRepoButton nameWithOwner={repoName} showDropdown={showDropdown} setShowDropdown={setShowDropdown} /> : <div className="group items-center justify-center text-center">
          <Button className={`group relative my-1 w-28 gap-1 py-0.5 text-xs`} onClick={() => handleFirstClick()} disabled={upsertingContact}>
            {upsertingContact ? <div className="flex items-center gap-1 text-white">
                Processing
                <LoadingSpinner className="h-3 w-3" />
              </div> : <>
                Add Contact
                <ChevronDownIcon className="h-4 w-4" />
              </>}
          </Button>
        </div>}
    </>;
}