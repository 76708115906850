import { CategoryScale, Chart as ChartJS, Filler, LineElement, LinearScale, PointElement, type ChartData } from "chart.js";
import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { options } from "~/lib/chartjs";
import { formatter } from "~/lib/numbers";
import { useLatestEvaluation } from "~/providers/EvaluationProvider";
import type { RepoEvaluation, UserEvaluation, RepoEvaluationResult, UserEvaluationResult } from "@openqlabs/drm-db";
function prepareChartData(evaluationResult: UserEvaluationResult | RepoEvaluationResult) {
  const commits = evaluationResult.commitsByDayNormalized ?? [];
  commits.reverse();
  return {
    labels: Object.keys(evaluationResult.commitsByDay),
    datasets: [{
      data: commits,
      borderColor: "hsla(184,85%,77%,0.75)",
      borderWidth: 3,
      pointBorderWidth: 0,
      pointRadius: 0,
      pointHoverRadius: 0,
      fill: false,
      tension: 0.4
    }]
  };
}
export default function CardActivityChart() {
  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Filler);
  const lastEvaluation = useLatestEvaluation<UserEvaluation | RepoEvaluation>();
  const [data, setData] = useState<ChartData<"line"> | null>(null);
  useEffect(() => {
    if (lastEvaluation?.result) {
      setData(prepareChartData(lastEvaluation.result));
    }
  }, [lastEvaluation]);
  if (!lastEvaluation || !data) {
    return <div className="px-8">Waiting for Evaluation . . . </div>;
  }
  const nbDaysCommit = (lastEvaluation?.result && Object.keys(lastEvaluation.result.commitsByDay ?? {})?.length) ?? 0;
  return <div data-sentry-component="CardActivityChart" data-sentry-source-file="ActivityChart.tsx">
      <div className="mb-3 flex items-center justify-center space-x-12 text-center dark:text-gray-400">
        <div>
          {formatter.format(lastEvaluation.result.commitCount)} changes
          <div className="mr-2 mt-1 h-1 w-full rounded-full bg-teal"></div>
        </div>
      </div>
      {nbDaysCommit > 1 ? <Line data={data} options={options} /> : <p className="mt-2 text-center text-sm text-gray-400">
          No commit activity data available to generate a graph
        </p>}
    </div>;
}