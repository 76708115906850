import { useRouter } from "next/router";
import { useMemo, useRef, useState } from "react";
import { useLists } from "~/providers/ListsProvider";
import { useTeamAccount } from "~/providers/TeamAccountProvider";
import { useTracker } from "~/providers/TrackerProvider";
import { api } from "~/utils/api";
import type { ComprehensiveContactData, Contact } from "~/server/api/model/listContact";

// to handle cases where input is to big for the url string we use posts instead of queries, here are the custom hooks that wrap those as a react query useQuery
import { useQuery } from "@tanstack/react-query";
const useEvaluationsQuery = ({
  listId,
  type,
  contactId,
  teamAccountId,
  trackerName
}: {
  listId?: string;
  type: "repo" | "user" | "repo-dependencies" | "user-dependencies";
  contactId: string;
  teamAccountId: string;
  trackerName?: string;
}) => {
  const client = api.useContext().client;
  return useQuery({
    queryKey: ["evaluations", listId, type, contactId, teamAccountId],
    queryFn: async () => {
      const optionalTrackerName = trackerName ? {
        trackerName
      } : {};
      return await client.evaluation.evaluationsByTypeAndContactId.mutate({
        listId,
        type,
        contactId: contactId,
        teamAccountId,
        ...optionalTrackerName
      });
    }
  });
};
const useComprehensiveContactData = (type: "repo" | "repo-dependencies" | "user" | "user-dependencies", contact: Contact): ComprehensiveContactData | null => {
  const githubContactId = (contact?.githubContactId as string);
  const tracker = useTracker();
  const listId = (useRouter().query.listId as string);
  const {
    lists
  } = useLists();
  const {
    activeTeamAccount
  } = useTeamAccount();
  const [hasLoadedSomeEvals, setHasLoadedSomeEvals] = useState<boolean>(false);
  const {
    data: evaluations
  } = useEvaluationsQuery({
    listId,
    type,
    contactId: githubContactId,
    teamAccountId: activeTeamAccount?.id
  });
  const {
    data: dependencyEvaluations
  } = useEvaluationsQuery({
    listId,
    type: (type.concat("-dependencies") as typeof type),
    contactId: githubContactId,
    teamAccountId: activeTeamAccount?.id,
    trackerName: tracker?.trackerName
  });
  const currentContactMap = useRef<ComprehensiveContactData | null>(null);
  return useMemo(() => {
    const evaluation = evaluations?.find(evaluation => {
      return evaluation.githubContactId === contact?.githubContactId;
    }) ?? null;
    const depsEvaluation = dependencyEvaluations?.find(evaluation => {
      return evaluation.githubContactId === contact?.githubContactId;
    }) ?? null;
    const list = lists.find(list => list.id === depsEvaluation?.listId) || lists[0];
    const result: ComprehensiveContactData = {
      githubEvaluation: evaluation,
      githubDepsEvaluation: depsEvaluation,
      list,
      contact: (contact as NonNullable<Contact>),
      tracker: tracker ?? null
    };
    const firstResult = result;
    const hasFirstResultLoadedEvals = firstResult?.githubEvaluation;
    if (!hasLoadedSomeEvals) {
      if (hasFirstResultLoadedEvals) setHasLoadedSomeEvals(true);
      currentContactMap.current = result;
      return result;
    }
    if (hasLoadedSomeEvals) {
      if (firstResult?.githubEvaluation) {
        currentContactMap.current = result;
        return result;
      }
    }
    return currentContactMap.current;
  }, [lists, evaluations, contact, dependencyEvaluations, hasLoadedSomeEvals, tracker]);
};
export default useComprehensiveContactData;