import { ArrowPathIcon, ChartPieIcon, TrashIcon } from "@heroicons/react/24/outline";
import { api } from "~/utils/api";
export interface SettingsItem {
  name: string;
  icon: typeof ChartPieIcon | typeof ArrowPathIcon | typeof TrashIcon;
  id: string;
}
const useSettings = (initialSettingsItems: SettingsItem[], githubName: string, teamAccountId: string) => {
  const {
    data: lists,
    refetch: refetchSettingsList
  } = api.list.listsWithoutGithubContactId.useQuery({
    githubName,
    teamAccountId
  });
  const settingItems = lists ? lists?.map(list => {
    return {
      name: `Add to ${list.name}`,
      icon: ChartPieIcon,
      id: list.id
    };
  }).concat(initialSettingsItems) : initialSettingsItems;
  return {
    settingItems,
    refetchSettingsList
  };
};
export default useSettings;