import { BarElement, CategoryScale, Chart as ChartJS, Filler, LineElement, LinearScale, PointElement, Tooltip, type ChartData } from "chart.js";
import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { optionsBothAxisAndStackedBar } from "~/lib/chartjs";
import { type UserRepoSummary } from "@openqlabs/drm-db";
import { getAllMonths } from "@openqlabs/utils";
function prepareChartData(months: string[], totalCommits: number[], totalActiveCommitsByRepo: {
  repoName: string;
  activeRepoCommits: number[];
}[]) {
  const onlyActiveCommitsByRepo = totalActiveCommitsByRepo.filter(activeCommitsByRepo => {
    return activeCommitsByRepo.activeRepoCommits?.some(activeCommits => activeCommits > 0);
  });
  const stackedBarData = onlyActiveCommitsByRepo ? onlyActiveCommitsByRepo.map((activeCommitsByRepo, index) => ({
    type: "bar",
    label: activeCommitsByRepo.repoName,
    data: activeCommitsByRepo.activeRepoCommits,
    backgroundColor: [`hsla(${index * 30}, 70%, 70%, 0.75)`],
    borderWidth: 1.5,
    yAxisID: "bar-y-axis",
    options: {
      responsive: true,
      scales: {
        x: {
          stacked: true
        },
        y: {
          stacked: true,
          beginAtZero: true
        }
      }
    }
  })) : {};
  return ({
    labels: months,
    datasets: [{
      type: "line",
      label: "Overall activity",
      data: totalCommits,
      borderColor: "hsla(248, 83%, 58%, 0.75)",
      backgroundColor: "hsla(248, 83%, 58%, 0.75)",
      borderWidth: 1.5,
      pointBorderWidth: 0,
      pointRadius: 0,
      pointHoverRadius: 0,
      fill: false,
      tension: 0.4,
      yAxisID: "line-y-axis"
    }, ...(Array.isArray(stackedBarData) ? stackedBarData : [])]
  } as ChartData<"bar"> | null);
}
export default function UserActivityHistoryChart({
  title,
  userRepoSummary,
  dependency
}: Readonly<{
  title: string;
  userRepoSummary: UserRepoSummary[];
  dependency: string;
}>) {
  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Filler, BarElement, Tooltip);
  const [data, setData] = useState<ChartData<"bar"> | null>(null);
  const allMonths = getAllMonths(userRepoSummary);
  const months = allMonths.sort() ?? [];
  const totalCommits = months.map(month => userRepoSummary.reduce((acc, repo) => {
    const monthActivity = repo?.userActivityWithUniqueMonth?.find(activity => activity.month === month);
    return acc + (monthActivity?.true_count ?? 0) + (monthActivity?.false_count ?? 0);
  }, 0)) ?? [];
  const totalActiveCommitsByRepo = userRepoSummary.map(elem => {
    const resultArray = months.map(month => {
      const currentMonth = elem.userActivityWithUniqueMonth.find(elem => elem.month === month)?.true_count ?? 0;
      return currentMonth;
    });
    return {
      repoName: elem.repoName,
      activeRepoCommits: resultArray
    };
  });
  const noTotalCommits = totalCommits.every(commit => commit === 0);
  useEffect(() => {
    if (!userRepoSummary || Object.keys(userRepoSummary).length === 0) return;
    setData(prepareChartData(months, totalCommits, totalActiveCommitsByRepo));
  }, [userRepoSummary, dependency]);
  const missingData = !months || !data || !userRepoSummary || noTotalCommits;
  return <>
      {!missingData && <div className="mb-20">
          <div className="flex justify-between pt-2 mb-4 ">
            <div className="flex items-center gap-2">
              <h3 className="text-xl font-semibold">{title}</h3>
            </div>
          </div>

          <div className="mt-8 max-w-[950px]">
            <div className="h-[300px]">
              <Bar data={data} options={optionsBothAxisAndStackedBar} />
            </div>
          </div>
        </div>}{" "}
    </>;
}