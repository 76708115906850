import { ChevronDownIcon } from "@primer/octicons-react";
import type { List } from "@openqlabs/drm-db";
import { useState, type Dispatch, type SetStateAction } from "react";
import LoadingSpinner from "~/loaders/LoadingSpinner";
import Button from "~/components/base/Button";
import DropDown from "~/components/base/DropDown";
import { useLists } from "~/providers/ListsProvider";
import { useTeamAccount } from "~/providers/TeamAccountProvider";
import { useTeamAccountUser } from "~/providers/TeamAccountUserProvider";
import { api } from "~/utils/api";
import { useContact } from "~/providers/ContactProvider";
import useSettings from "~/hooks/useSettings";
export default function AddUserRepoButton({
  nameWithOwner,
  showDropdown,
  setShowDropdown
}: Readonly<{
  nameWithOwner: string;
  showDropdown: boolean;
  setShowDropdown: Dispatch<SetStateAction<boolean>>;
}>) {
  const {
    activeTeamAccount
  } = useTeamAccount();
  const [teamAccountUser] = useTeamAccountUser();
  const listContext = useLists();
  const {
    contact
  } = useContact();
  const lists = (listContext?.lists as List[]);
  const [isAddingContact, setIsAddingContact] = useState<boolean>(false);
  const {
    settingItems,
    refetchSettingsList
  } = useSettings([], (contact?.githubName as string), activeTeamAccount.id);
  const {
    mutate: updateContactBatch
  } = api.contactBatchCreation.create.useMutation({
    onSuccess: async () => {
      await refetchSettingsList();
    }
  });
  const selectItem = async (_name: string, id?: string | undefined) => {
    if (id && teamAccountUser) {
      setIsAddingContact(true);
      updateContactBatch({
        type: "repo",
        listId: id,
        teamAccountId: activeTeamAccount.id,
        userId: teamAccountUser.id,
        githubUrls: [{
          url: nameWithOwner,
          customFields: []
        }]
      });
      setIsAddingContact(false);
      setShowDropdown(false);
    }
  };
  const contactAlreadyAddedEverywhere = Boolean(lists.length > 0 && settingItems.length === 0);
  return <div className="group items-center justify-center text-center" data-sentry-component="AddUserRepoButton" data-sentry-source-file="AddUserRepoButton.tsx">
      <Button className={`group relative my-1 w-28 gap-1 py-0.5 text-xs ${contactAlreadyAddedEverywhere ? "border-gray-400 bg-gray-200 hover:bg-gray-400" : ""}`} onClick={() => setShowDropdown(true)} disabled={isAddingContact || contactAlreadyAddedEverywhere} data-sentry-element="Button" data-sentry-source-file="AddUserRepoButton.tsx">
        {contactAlreadyAddedEverywhere && <>Contact added</>}
        {isAddingContact && <div className="flex items-center gap-1 text-white">
            Processing
            <LoadingSpinner className="h-3 w-3" />
          </div>}
        {!isAddingContact && !contactAlreadyAddedEverywhere && <>
            Add Contact
            <ChevronDownIcon className="h-4 w-4" />
            {showDropdown && <DropDown closeDropDown={() => setShowDropdown(false)} className="btn absolute right-0 top-6 w-48 bg-offwhite px-0 text-sm text-gray-600" items={settingItems} filterOpen={showDropdown} selectItem={(name: string, id?: string) => selectItem(name, id)} listClassName="hover:bg-accent hover:text-white px-4" />}
          </>}
      </Button>
      {contactAlreadyAddedEverywhere && <div className="relative z-50 opacity-0 group-hover:opacity-100">
          <div className="absolute -ml-40 -mt-12 whitespace-nowrap bg-white px-1 text-sm font-normal text-gray-800">
            Contact already added to all lists
          </div>
        </div>}
    </div>;
}