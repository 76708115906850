import { createContext, useContext, type ReactNode } from "react";
import type { EvaluationType, LiveEvaluation } from "~/server/api/model/evaluation";
const latestEvaluationContext = createContext<LiveEvaluation>(null);
export function useLatestEvaluation<TEvaluation>() {
  return (useContext(latestEvaluationContext) as TEvaluation);
}
export function EvaluationProvider({
  children,
  evaluation
}: {
  children: ReactNode;
  type: EvaluationType;
  evaluation: LiveEvaluation | undefined;
}) {
  return <latestEvaluationContext.Provider value={evaluation ?? null} data-sentry-element="unknown" data-sentry-component="EvaluationProvider" data-sentry-source-file="EvaluationProvider.tsx">
      {children}
    </latestEvaluationContext.Provider>;
}