import { getRepoTooltip, getStatusColor, getStatusText } from "~/lib/dependencyHelpers";
import { type UserDependenciesEvaluation } from "@openqlabs/drm-db";
import AddUserRepoToList from "../UserDependenciesPresentation/AddUserRepoToList";
import { useList } from "~/providers/ListProvider";
import { useContact } from "~/providers/ContactProvider";
import Link from "next/link";
import { decodeStatusAsObject } from "@openqlabs/utils";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
export default function UserDependenciesFoundPresentation({
  latestDepsEvaluation,
  dependenciesSearched,
  trackerName
}: Readonly<{
  latestDepsEvaluation: UserDependenciesEvaluation;
  dependenciesSearched: string[];
  trackerName: string;
}>) {
  const list = useList();
  if (!list) return null;
  const {
    contact
  } = useContact();
  const depStatusObj = decodeStatusAsObject(contact.dependencyStatusNumber, undefined);

  // only keeping the below code because of the multiple dep logic for now

  return <div className="flex flex-col pt-2" data-sentry-component="UserDependenciesFoundPresentation" data-sentry-source-file="index.tsx">
      <div className="flex">
        <span className="font-bold">
          {trackerName.concat(": ", dependenciesSearched.join(", "))}
        </span>
        :
        <div className={`group mx-2 whitespace-nowrap rounded-md px-4 ${depStatusObj.color}`}>
          {depStatusObj.status}
          <div className="relative z-50 opacity-0 group-hover:opacity-100">
            <div className="absolute -mt-10 whitespace-nowrap bg-white px-1">
              {depStatusObj.tooltip}
            </div>
          </div>
        </div>
      </div>
      <div className="py-4">
        <div className="flex flex-col py-2">
          {latestDepsEvaluation.result.userRepoSummary.map((repoSummary, index) => {
          const repoName = repoSummary.repoName;
          const repoStatus = repoSummary.repoStatus;
          const repoText = repoSummary?.manualStatus ? "active" : getStatusText(repoStatus);
          const noDepFound = !repoSummary?.earliestFound && !repoSummary?.manualStatus;
          return <div className="flex items-center justify-between" key={repoName}>
                  <div key={repoName && index} className="group flex items-center space-x-1 py-2 pr-4">
                    <div className={`h-3 w-3 rounded-full ${getStatusColor(repoStatus)}`} />
                    <Link href={`https://github.com/${repoName}`} target="_blank">
                      <div className="px-1 flex gap-1">
                        {repoName}
                        <ArrowTopRightOnSquareIcon className="h-4 w-4 inline-block" />
                      </div>
                    </Link>
                    <div className={`whitespace-nowrap rounded-md px-4 ${getStatusColor(repoStatus)}`}>
                      {repoText}
                    </div>
                    {!noDepFound && <div className="relative z-50 opacity-0 group-hover:opacity-100">
                        <div className="absolute -ml-60 -mt-6 whitespace-nowrap bg-white px-1">
                          {getRepoTooltip(repoSummary.repoStatus, repoSummary?.earliestFound ?? "", repoSummary.dateRemoved, repoSummary.manualStatus)}
                        </div>
                      </div>}
                  </div>
                  <AddUserRepoToList repoName={repoName} key={repoName} />
                </div>;
        })}
        </div>
      </div>
    </div>;
}